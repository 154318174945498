import { Text, createStyles } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

import flagEn from "public/icon_EnFlag.svg";
import flagVn from "public/icon_VnFlag.svg";

const useStyles = createStyles(() => ({
  language: {
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  },
  languageItem: {
    marginTop:"10px",
    /* marginRight: "10px", */
    marginLeft:"12px",
    cursor: "pointer",
    width: 27,
    height: 18,
  },
  languageNotActive: {
    filter: "grayscale(100%)",
  },
}));

export default function Language() {
  const { classes } = useStyles();

  const {
    locale,
    // pathname,
    asPath,
    // query: { slug },
  } = useRouter();

  return (
    <div className={classes.language}>
      <Link scroll={false} href={asPath} locale="vi">
        <a>
          <div
            className={
              `${classes.languageItem 
              } ` +
              `${locale !== "vi" ? classes.languageNotActive : ""}`
            }
          >
            <Image src={flagVn} alt="Ngôn ngữ Việt" width="100%" height="100%" objectFit="contain"/>
          </div>
        </a>
      </Link>
      <Link scroll={false} href={asPath} locale="en">
        <a>
          <div
            className={
              `${classes.languageItem 
              } ` +
              `${locale !== "en" ? classes.languageNotActive : ""}`
            }
          >
            <Image src={flagEn} alt="English" width="100%" height="100%" objectFit="cover"/>
          </div>
        </a>
      </Link>
    </div>
  );
}
