/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */

import useAuth from "@/hooks/useAuth";
import useTrans from "@/hooks/useTrans";
import useUserInfo from "@/hooks/useUserInfo";
import useWindowSize from "@/hooks/useWindowSize";
import { CartContext, useCartContext } from "@/setup/cartProvider";
import { Avatar, Box, Burger, Button, Grid, Group, Indicator, Menu, Popover, Text, UnstyledButton, useMantineTheme, } from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import { RiShoppingBag2Line } from "@react-icons/all-files/ri/RiShoppingBag2Line";
import { RiShoppingBag3Fill } from "@react-icons/all-files/ri/RiShoppingBag3Fill";
import Image from "next/image";
import Link from "next/link";
import iconLogin from "public/icon_login.svg";
import iconPurchase from "public/icon_purchase.png";
import iconPurchaseMobile from "public/icon_purchase_mobile.svg";
import iconSearch from "public/icon_search.svg";
import iconShopping from "public/icon_shopping.svg";
import logo from "public/logo.png";
import React, { useContext } from "react";
import appendImageUrlFromAPI from "utils/appendImageUrlFromAPI";
import DrawerMenu from "./components/DrawerMenu";
import Language from "./components/Language";
import MiniCart from "./components/MiniCart";
import SearchBar from "./components/SearchBar";
import useStyles from "./styles";

export default function Header() {
  const { userInfo, isLogin } = useUserInfo();
  const trans = useTrans();
  const context = useCartContext();
  const [openCart, setOpenCart] = React.useState(false);

  const { logout } = useAuth();
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const menuRef = React.useRef();

  const [openedBurger, { toggle }] = useDisclosure(false);
  const label = openedBurger ? 'Close navigation' : 'Open navigation';
  const [opened, setOpened] = React.useState(false);
  const [iconCart, setIconCart] = React.useState(<RiShoppingBag2Line />);
  const {isMobile, isTablet} = useWindowSize();
  const { id_afflink} = useContext(CartContext);
  // console.log('afflink trong header', id_afflink)

  React.useEffect(() => {
    if (openCart) {
      setIconCart(<RiShoppingBag3Fill />);
    } else {
      setIconCart(<RiShoppingBag2Line />);
    }
  }, [openCart]);


  return (
    <>
            {isMobile||isTablet ? (
              <div className={classes.containerMobile}>
                <Grid justify="space-around" align="center" grow gutter={50}>
                  <Grid.Col  span={3} style={{display: 'flex', justifyContent: 'flex-start'}}>
                    <Burger onClick={() => {setOpened(true); toggle()}} 
                    // opened={openedBurger}
                    />
                    <DrawerMenu opened={opened} setOpened={setOpened} />
                  </Grid.Col>
                  <Grid.Col  span={3} style={{ display: 'flex', justifyContent: 'center',}}>
                    <Link scroll={false} href="/" aria-label="Home Page">
                      <a><Image src={logo} alt="Việt Thiên" title="Việt Thiên" width={55} height={34}/></a>                  
                    </Link>
                  </Grid.Col>
                  <Grid.Col  span={3} style={{ display: 'flex', justifyContent: "flex-end", }}>

                  <div style={{marginRight:"8px"}}><Language/></div>

                      <Link scroll={false} href="/mua-hang/san-pham?sort=newest" aria-label="Buy Products" ref={menuRef} style={{ display: "flex", alignItems: "center" }}>
                        <UnstyledButton>                      
                          <Image src={iconPurchaseMobile} alt="purchase" title="purchase" width={27} height={27} objectFit="contain"  />
                        </UnstyledButton>
                      </Link>
                    <Popover width={340} position="bottom" withArrow shadow="md" arrowOffset={50}
                      styles={{
                        arrow: { left: "auto !important", right: "15px" },
                        dropdown: {
                          left: "auto !important",
                          right: 20,
                        },
                      }}
                      onOpen={() => setIconCart(<RiShoppingBag3Fill />)}
                      onClose={() => setIconCart(<RiShoppingBag2Line />)}
                      onChange={setOpenCart}
                      opened={openCart}
                    >
                      <Popover.Target>
                        <Box sx={{ fontSize: "20px", marginLeft:"5px", marginRight: "5px", display: "flex", alignItems: "center", cursor: "pointer",}}
                             onClick={() => setOpenCart((o) => !o)}
                        >
                            <UnstyledButton><Image src={iconShopping} alt="Shopping Bag" title="Shopping Bag" width={29} height={29} objectFit="cover"/></UnstyledButton>
                        </Box>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <MiniCart onClose={() => setOpenCart(false)} />
                      </Popover.Dropdown>
                    </Popover>
                      <Link href="/dang-nhap" aria-label="Login">
                            <UnstyledButton>
                              <Image src={iconLogin} alt="login" title="Longin" width={27} height={27} objectFit="cover"/>
                            </UnstyledButton>
                          </Link>
                    </Grid.Col>
              </Grid>
              </div>  
            ):(
              <div className={classes.container}>
              <Grid align="center" justify="space-between" sx={{  height:"100%", width: "100%", }} gutter={0} grow>
                <Grid.Col md={1} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link scroll={false} href="/" aria-label="Home Page">
                        <a><Image src={logo} alt="Việt Thiên" title="Logo Việt Thiên" width={108} height={67}/></a>                 
                    </Link>
                </Grid.Col>
                <Grid.Col md={3}>
                  <div className={`${classes.mainMenu} ${classes.wrapper}`}>    
                      <Link scroll={false} href="/gioi-thieu" aria-label="About us" id="main-menu-item" ref={menuRef} style={{ display: "flex", alignItems: "center" }}>
                        <a><div className={classes.menuItem}><Text size="lg">{trans?.menu.about.title}</Text></div></a>
                      </Link>
                    <Menu trigger="hover" position="bottom-start" offset={0} withArrow>
                      <Menu.Target>
                        <div><Link scroll={false} href="/san-pham" aria-label="product" id="main-menu-item" ref={menuRef} style={{ display: "flex", alignItems: "center" }}>
                          <a><div className={classes.menuItem}><Text size="lg">{trans?.menu.products.title}</Text></div></a>
                        </Link>
                        </div>
                          {/* <div className={classes.menuItem} ><Text size="lg">{trans?.menu.products.title}</Text></div> */}
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item>
                          <Link scroll={false} href="/san-pham/tra" aria-label="Tea">
                            <a><div><Text size="md">{trans?.menu.products.tea}</Text></div></a>
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link scroll={false} href="/san-pham/ca-phe" aria-label="Coffee">
                            <a><div><Text size="md">{trans?.menu.products.coffee}</Text></div></a>
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link scroll={false} href="/san-pham/nong-san" aria-label="Agricuture">
                            <a><div><Text size="md">{trans?.menu.products.agricultural}</Text></div></a>
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link scroll={false} href="/san-pham/nuoc-tang-luc" aria-label="Agricuture">
                            <a><div><Text size="md">{trans?.menu.products.energyDrink}</Text></div></a>
                          </Link>
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>

                      <Link scroll={false} href="/noi-dia" aria-label="Domestic" id="main-menu-item" ref={menuRef} style={{ display: "flex", alignItems: "center" }}>
                        <a><div className={classes.menuItem}><Text size="lg">{trans?.menu.noidia.title}</Text></div></a>
                      </Link>
                      <Link scroll={false} href="/xuat-khau" aria-label="Export" id="main-menu-item" ref={menuRef} style={{ display: "flex", alignItems: "center" }}>
                        <a><div className={classes.menuItem}><Text size="lg">{trans?.menu.xuatkhau.title}</Text></div></a>
                      </Link>
                    <Menu trigger="hover" position="bottom-start" offset={0} withArrow>
                      <Menu.Target>
                        <div><Link scroll={false} href="/tin-tuc" aria-label="news" id="main-menu-item" ref={menuRef} style={{ display: "flex", alignItems: "center" }}>
                          <a><div className={classes.menuItem}><Text size="lg">{trans?.menu.news.title}</Text></div></a>
                        </Link>
                        </div>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item>
                          <Link scroll={false} href="/tin-tuc/tra" aria-label="tea">
                            <a><div><Text size="md">{trans?.menu.news.newsTea}</Text></div></a>
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link scroll={false} href="/tin-tuc/ca-phe" aria-label="coffee">
                            <a><div><Text size="md">{trans?.menu.news.newsCoffee}</Text></div> </a>
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link scroll={false} href="/tin-tuc/nong-san" aria-label="agricuture">
                            <a><div><Text size="md">{trans?.menu.news.newsAgri}</Text></div></a>
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link scroll={false} href="/tuyen-dung" aria-label="Recruitment" >
                            <a><div><Text size="md">{trans?.menu?.recruitment?.title}</Text></div></a>
                          </Link>
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                      <Link scroll={false} href="/lien-he" aria-label="Contact" id="main-menu-item" ref={menuRef} style={{ display: "flex", alignItems: "center" }}>
                        <a><div className={classes.menuItem}><Text size="lg">{trans?.menu.contact.title}</Text></div></a>
                      </Link>
                  </div>
                </Grid.Col>
            
            <Grid.Col md={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <div className={classes.rightSide}>
                <div className={classes.optionsWrapper}>
                  <Popover width={200} position="bottom" withArrow shadow="md">                   
                    <Popover.Target>                                 
                        <UnstyledButton> <Image src={iconSearch} alt="search" title="Search" width={24} height={24} objectFit="cover"/></UnstyledButton>                      
                    </Popover.Target>
                    <Popover.Dropdown>
                      <SearchBar/>
                    </Popover.Dropdown>
                  </Popover>

                  <Popover width={340} position="bottom" withArrow shadow="md" arrowOffset={50}
                    styles={{
                      arrow: { left: "auto !important", right: "15px" },
                      dropdown: {
                        left: "auto !important",
                        right: 20,
                      },
                    }}
                    onOpen={() => setIconCart(<RiShoppingBag3Fill />)} onClose={() => setIconCart(<RiShoppingBag2Line />)} onChange={setOpenCart} opened={openCart}
                  >
                    <Popover.Target>
                      <Box sx={{ fontSize: "20px", margin: "10px", display: "flex", alignItems: "center", cursor: "pointer",}} onClick={() => setOpenCart((o) => !o)}>
                        <Indicator
                          label={<Text color="#fff">{context?.cart?.length || 0}</Text>}
                          size={20} radius="50%" color={theme.colors.main[0]}
                        > <div> 
                            <Image src={iconShopping} alt="Shopping Bag" title="Shopping Bag" width={24} height={24} objectFit="cover"/>
                          </div>
                        </Indicator>
                      </Box>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <MiniCart onClose={() => setOpenCart(false)} />
                    </Popover.Dropdown>
                  </Popover>

                  <div className={classes.authWrapper}>
                    {isLogin ? (
                      <div>
                        <Menu shadow="md" width={200}>
                          <Menu.Target>
                            {userInfo && (
                              <Group sx={{ cursor: "pointer" }}>
                                <Avatar src={appendImageUrlFromAPI({ src: userInfo?.picture,})} alt={userInfo?.name} radius="xl" size="sm"/>
                                <div style={{ display: "flex", flex: 1, alignItems: "center",}}>
                                  <Text size="sm" weight="500">{userInfo?.name}</Text>
                                </div>
                              </Group>
                            )}
                          </Menu.Target>
                          <Menu.Dropdown>
                            <Menu.Label>{trans.profile.manager.title}</Menu.Label>
                            <Link scroll={false} href="/user-profile/my-profile" aria-label="Profile">
                              <a><Menu.Item>{trans.profile.manager.info}</Menu.Item></a>
                            </Link>
                            <Link scroll={false} href="/user-profile/address-book" aria-label="address book">
                              <a><Menu.Item>{trans.profile.manager.address}</Menu.Item></a>
                            </Link>
                            <Link scroll={false} href="/user-profile/purchased-return" aria-label="purchased return">
                              <a><Menu.Item>{trans.profile.manager.purchase}</Menu.Item></a>
                            </Link>
                            <Link scroll={false} href="/user-profile/liked-product" aria-label="liked products">
                              <a><Menu.Item>{trans.profile.manager.liked}</Menu.Item></a>
                            </Link>
                            <Menu.Divider />
                            <Menu.Label>{trans.profile.manager.adjust}</Menu.Label>
                            <Menu.Item onClick={() => logout(userInfo?.token)}><div>{trans.profile.manager.logout}</div></Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      </div>
                    ) : (
                      <div style={{paddingRight:"10px"}}>
                        <Link href="/dang-nhap" aria-label="login"><a><Image src={iconLogin}alt="login"  title="Login" width={24} height={24} objectFit="cover"/></a></Link>
                      </div>
                    )} 
                  </div>
{/*                   
                  <Popover position="bottom" withArrow shadow="md">
                          <Popover.Target> */}
                            <Language/>
                          {/* </Popover.Target>
                  </Popover> */}

                  <Menu trigger="hover" openDelay={100} style={{paddingLeft: "8px"}}>
                    <Menu.Target>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {
                          id_afflink && Object.keys(id_afflink).length === 0 ? (
                            <Link
                              scroll={false}
                              href="/mua-hang/san-pham?sort=newest"
                              aria-label="products"
                              id="main-menu-item"
                              ref={menuRef}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <a target="_blank" aria-label="products">
                                <Button color="#12854F" radius={50}>   
                                <Image src={iconPurchase} alt="purchase" title="purchase" width={20} height={20} objectFit="cover"/>
                                    <div className={classes.menuItem}>
                                      <Text size="15" weight="400" color="#FFF" style={{ marginLeft: "8px" }}>
                                        {trans?.menu.muahang.title}
                                      </Text>
                                    </div>
                                </Button>    
                              </a>
                            </Link>
                          ) : (
                            <Link
                              scroll={false}
                              href={`/mua-hang/san-pham?sort=newest&afflink=${id_afflink}`}
                              aria-label="products"
                              id="main-menu-item"
                              ref={menuRef}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <a target="_blank" aria-label="products">
                                <Button color="#12854F" radius={50}>   
                                    <Image src={iconPurchase} alt="purchase" title="purchase" width={20} height={20} objectFit="cover"/>
                                    <div className={classes.menuItem}>
                                      <Text size="15" weight="400" color="#FFF" style={{ marginLeft: "8px" }}>
                                        {trans?.menu.muahang.title}
                                      </Text>
                                    </div>
                                </Button>  
                              </a>
                            </Link>
                          )
                        }

                        </div>        
                    </Menu.Target>
                  </Menu>
                </div>
              </div>
            </Grid.Col>
          </Grid>
          </div>
          )}
          {/* </Container> */}
    </>
  );
}