export default {
  menu: {
    about: {
      title: "About Us",
    },
    products: {
      title: "Products",
      tea: "Tea",
      coffee: "Coffee",
      agricultural: "Agricultural",
      energyDrink: "Energy Drink",
    },
    promotion: {
      title: "Promotions",
    },
    news: {
      title: "News",
      newsTea: "Tea news",
      newsCoffee: "Coffee news",
      newsAgri: "Agricultural news",
    },
    noidia: {
      title: "Domestic",
    },
    xuatkhau: {
      title: "Export",
    },
    recruitment:{
      title:'Jobs',
    },
    muahang: {
      title: "Purchase",
    },
    contact: {
      title: "Contact",
    },
    search: {
      title: "Search",
    },
    profile: {
      title: "Profile",
    },
    language:{
      title: "Language",
    }
  },
  miniCart: {
    titleA: "You have",
    titleB: "items in cart",
    statusStock: "Stocking",
    labelTotal: "Total",
    btnCart: "Cart",
    btnPay: "Pay Now",
  },
  homeAbout:{
    product: "Products",
    seeMore: "See more",
  },
  profile: {
    manager: {
      home: "Home",
      title: "Account Management",
      info: "Account information",
      info_dcr: "Manage your personal account information",
      address: "Address book",
      address_dcr: "Manage your shipping and billing addresses",
      purchase: "Orders purchased",
      purchase_dcr: "Manage orders that you have purchased or returned",
      liked: "Favorite product",
      liked_dcr: "Manage list of favorite products",
      adjust: "Options",
      logout: "Log out",
      register: "Register",
      login: "Log in",
    },
    layout: {
      home: "Home",
      title: "Account Management",
      info: "Account information",
      address: "Address book",
      purchase: "Orders purchased",
      liked: "Favorite product",
    },
    info: {
      info: "Account information",
      account: "Username",
      name: "Full name",
      date: "Date of birth",
      sex: "Gender",
      male: "Male",
      female: "Female",
      pass: "Password",
      changePass: "Change Password",
      confirm: "Confirm information change",
      currPass: "current password",
      newPass: "A new password",
      conPass: "Confirm new password",
      valPass: "Minimum 6 characters including uppercase, lowercase and number",
      noti: "Update successful",
      notiFail: "Update failed",
      valName: "Please enter your full name",
    },
    address: {
      title: "Address book",
      default: " - Default address",
      addAddress: "Add address",
      creatAddress: "Create a new address book",
      changeAddress: "Edit",
      deleteAddress: "Delete",
      deleteNoti: "Address removed",
      province: "Province",
      district: "District",
      ward: "Ward",
      slProvince: "Choose a province",
      slDistrict: "Choose a district",
      slWard: "Choose a ward",
      setDF: "Set as default address",
      save: "Save address",
      changeNoti: "Information is being updated",
    },
    order: {
      order: "Order Management",
      title: "List of purchased orders",
      date: "Order date",
      datepredict: "Estimated delivery date",

      id: "Code orders",
      product: "Product",

      quantity: "Quantity",
      total: "Total amount",

      orderDetail: "Order details",
      name: "Receiver's  name",
      phone: "Phone number",
      address: "Receiver's address",
      detail: "Detail",
      unitPrice: "Unit price",
      price: "Into money",
      temp: "Provisional",
      ship: "Delivery charges",
      freeship: "Money Free Shipping",
      cancelOrder: "Cancellation",
      cancelReason: "Reason for cancellation",
      cancel_dcr: "The reason for your cancellation is: ",
      confirm: "Confirm",
      cancel: "Cancel",
      pay: "Continue to pay",
      rebuy: "Re-Purchase",
      cancelNoti_suc: "Order canceled",
      cancelNoti_fail: "Cancellation failed",
      cancelNoti_val: "Please enter all characters (>5)",
      rebuyNoti_suc: "Add to cart successfully",
      rebuyNoti_val: "Request is being processed",
    },
    liked: {
      title: "Favorite product",
      noliked: "You don't have a favorite product yet?",
    },
  },
  menuAuth: {
    account: "Account",
    profile: "Profile",
    address: "Address",
    order: "Order",
    liked: "Liked Products",
    option: "Option",
    logout: "Log out",
  },
  homeLiked: {
    title: "Favourite Teas",
  },
  homePromotion: {
    title: "Promotion",
  },
  homeNews: {
    title: "News",
  },
  subscribe: {
    title: "Sign up to receive information from Viet Thien",
    placeholder: "Enter your email",
    button: "Register",
    alertSuccess: "Subscribe success",
    alertFailed: "Subscribe failed",
  },
  footer: {
    addressLabel: "Address",
    customerPolicy: "Customer Policy",
    contactInfo: "Contact Info",
    followUs: "Follow Us",
    payments: "Payment methods",
    cod: "COD",
    bankTransfer: "Bank transfer",
    wallet: "Electronic wallet",
    info1:
      "Business Certificate Number: 0305693542 – Issued on Febuary 22, 2008",
    info2: "By the Department of Planning and Investment of HCM City.",
  },
  contact: {
    content:"If you have any concern or wish to cooperate, please leave your information, we will assist you as soon as possible.",
    taxCodeLabel: "Tax code",
    email: "Email",
    address: "Office",
    form: {
      title: "Contact us",
      fullName: "Name",
      email: "Email",
      phone: "Phone number",
      address: "Address",
      content: "Comments",
      button: "Submit",
    },
    placeholder: {
      fullName: "Your name",
      email: "Your email address",
      phone: "Your phone number",
      content: "Enter a comment here...",
      address: "Your address",
    },
    map: {
      title: "List of Viet Thien factories",
    },
  },
  collections: {
    filterCategory: "Category",
    filterPrice: "Price",
    remove: "Reset",
    from: "From",
    to: "To",
    establish: "Establish",
    searchResult: "Search results for: ",
    notfound: "No products found",
    notFoundPromotion: "No promotional products available",
    filter: "Filter",
    reset: "Reset",
    apply: "Apply",
    sort: "SORTED BY: ",
  },
  login: {
    seoTitle: "Log in - Viet Thien",
    title: "Log in to your account",
    username: "Email or phone number",
    password: "Password",
    forgotPass: "Forgot password?",
    submit: "LOG IN",
    swap: "You are not a member, please",
    or: "OR",
    loginFB: "Login with Facebook",
    signup: "Register",
    placeholder: {
      username: "Enter your email or phone number",
      password:
        "Minimum 6 characters including uppercase, lowercase and number",
    },
    validate: {
      blank: "Email or phone number cannot be left blank",
      blankPassword: "Password can not be blank",
    },
  },
  forgotPassword: {
    title: "Password recovery",
    username:
      "Email or phone number (we will send you an OTP to reset your password)",
    submit: "Submit and confirm",
    placeholder: {
      username: "Enter your email or phone number",
    },
    validate: {
      blank: "Username cannot be empty",
      send: "Sending verification code failed",
    },
  },
  resetPassword: {
    newPassword: "New Password",
    reNewPassword: "Retype Password",
    submit: "Change Password",
    placeholder:
      "Minimum 6 characters including uppercase, lowercase and number",
  },
  otpFrom: {
    title: "Verification codes",
    submit: "Confirm",
    resend: "Resend",
    placeholder: "Enter your code",
  },
  register: {
    seoTitle: "Register - Viet Thien",
    title: "Sign up for an account",
    phoneNumber:"Phone Number",
    emailAddress : "Email",
    userName: "Email or phone number",
    fullName: "Full name",
    password: "Password",
    rePassword: "Enter the password",
    role: "I have read and accepted ",
    policy: "rules",
    submit: "Register",
    swap: "You are already a member, please ",
    login: "Log in",
    linkPolicy: "/chinh-sach/privacy-policy",
    instructor:"Referral code",
    confirm:"I have read, understood and accepted",
    theTerms:"the terms",
    done:"Done",
    placeholder: {
      phoneNumber:"Enter phone number",
      emailAddress : "Enter email address",
      userName: "Enter your email or phone number",
      fullName: "Enter your first and last name",
      password1:"Enter password",
      password:
        "Minimum 6 characters including uppercase, lowercase and number",
      rePassword: "Reconfirm your password",
      instructor:"Enter Referral Code",
    },
    alert: {
      sendOtpSuccess: "Verification code sent to",
      sendOtpFailed: "Resend verification code failed",
      success: "Account successfully created! Sign in to shop now",
    },
    validate: {
      email: "Invalid email or phone number",
      blankPassword: "Password can not be blank",
      password:
        "Password must have at least 6 characters and at least 1 uppercase, 1 lowercase, 1 number",
      password1: "Re-enter password does not match",
    },
  },
  cart: {
    cartInfo: "Order Information",
    temp: "Provisional calculation",
    ship: "Delivery fee",
    freeship: "Free shipping",
    total: "Total estimates",
    pay: "Order",
    choose: "Choose now",
    avail: "In stock",
    typeShip: "Select a shipping unit",
    accept: "Accept",
    voucher: "Discount Code",
    chooseVoucher: "Enter your discount code",
  },
  noti: {
    title: "Notify",
  },
};
