import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
    height:"80px",
    boxShadow: "0 0 100px rgb(0 0 0 / 10%)",
    background: "#FFF",
    color: "#212F3B",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "400",
    position: "relative",
    zIndex: 10,
  },
  containerMobile: {
    width: "100%",
    height:"100%",
    boxShadow: "0 0 10px rgb(0 0 0 / 10%)",
    background: "#FFF",
    color: "#212F3B",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "400",
    position: "relative",
    zIndex: 10,
  },

  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFF",
    height: "80px",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: "50px",
    },
  },
  authWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: "none",
    },
  },
  rightSide: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: "none",
    },
  },
  mainMenu: {
    height:"50px",
    display: "flex",
    flexWrap: "wrap",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: "none",
    },
  },
  menuItem: {
    padding: "8px 0",
    fontFamily: "SVN-Gotham Bold",
    color: "#212F3B",
    transition: "0.3s",
    "&:hover": {
      color: theme.colors.second[3],
    },
    "&:frist-letter":{
      textTransform: "capitalize",
    },
  },
  
  optionsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

}));

export default useStyles;
