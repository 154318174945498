const title = "Việt Thiên";
const description = "";

const defaultSEOConfigs = {
  titleTemplate: "%s",
  defaultTitle: title,
  title,
  //
  description,
  openGraph: {
    type: "website",
    url: "https://vietthien.com/",
    title,
    // description,
    locale: "vi",
    siteName: "VietThien",
    images: [
      {
        url: `https://api.flexzen.app/api/615fa1791fc31763c7253668/file/download/654afc5ee5895a51f2c3f8dc?access_token=flex.public.token&trustkey=APP.FKJSHHHH908KJF&size=xl`,
        width: 380,
        height: 200,
        alt: "VietThien",
      },
    ],  
    
  },
};

export default defaultSEOConfigs;
