import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";

export default function ThemeProvider({ children }) {
  return (
    <MantineProvider
      // withCSSVariables
      withGlobalStyles
      withNormalizeCSS
      theme={{
        // colorScheme: ["light", "dark"], // BUG: override other css accidentally
        primaryShade: 5,
        dateFormat: "DD/MM/YYYY",
        datesLocale: "vi",
        radius: {
          xs: 4,
          sm: 6,
          md: 8,
          lg: 10,
          xl: 12,
        },
        colors: {
          main: [
            "#a2cebd", // 0
            "#8fc4af", // 1
            "#7dbaa2", // 2
            "#6ab195", // 3
            "#58a787", // 4
            "#459d7a", // 5
            "#3e8d6e", // 6
            "#377e62", // 7
            "#306e55", // 8
            "#295e49", // 9
          ],
          // second: [
          //   "#2f2415",
          //   "#463620",
          //   "#544127",
          //   "#624c2d",
          //   "#705733",
          //   "#7e613a",
          //   "#2f2415",
          //   "#9a7647",
          //   "#a8804d",
          //   "#b28b57",
          // ],
          second: [
            "#424955", // 0
            "#323842", // 1
            "#171A1F", // 2
            "#459D7A", // 3
            "#DEE1E6", // 4
            "#EC5A77", // 5
            "#323842", // 6
            "#323842", // 7
            "#323842", // 8
            "#323842", // 9
          ],
        },

        primaryColor: "main",

        // typo
        fontFamily: "SVN-Gotham ",
        fontSizes: {
          xs: 12,
          sm: 14,
          md: 16,
          lg: 18,
          xl: 20,
        },
        breakpoints: {
          xs: 576,
          sm: 768,
          md: 992,
          lg: 1200,
          xl: 1400,
        },
        headings: {
          fontFamily: "SVN-Gotham Bold",
          fontWeight: 700,
          sizes: {
            h1: { fontSize: 40 },
            h2: { fontSize: 34 },
            h3: { fontSize: 30 },
            h4: { fontSize: 24 },
            h5: { fontSize: 20 },
            h6: { fontSize: 16 },
          },
        },
      }}
    >
      <NotificationsProvider position="top-right" zIndex={2077} limit={3}>
        {children}
      </NotificationsProvider>
    </MantineProvider>
  );
}
