import useUserInfo from "@/hooks/useUserInfo";
import { config } from "@/setup/cartProvider";
import { useApiContext } from "@/setup/flexbiz-api-provider";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { asyncGetList } from "../fetch";
import { cartKeys } from "../queryKeys";

export const fetchCart = async ({ userInfo }) => {
  const { token, ...condition } = userInfo;

  if (!token) return [];

  try {
    const data = await asyncGetList({
      access_token: token,
      collection_name: "cart",
      options: { ...condition },
    });

    if (!data) return [];

    if (data && data.length <= 1) {
      return data;
    }

    return data.sort(
      (a, b) =>
        new Date(a.date_created).getTime() - new Date(b.date_created).getTime()
    );
  } catch (error) {
    console.log(
      "🚀 ~ file: cartFunctions.js ~ line 29 ~ fetchCart ~ error",
      error
    );
  }
};

export const useFetchCart = () => {
  const context = useApiContext();
  const { isLogin } = useUserInfo();
  const [info, setInfo] = useState({});

  const { userInfo } = context || {};
  useEffect(() => {
    setInfo({
      token:
        isLogin !== false && userInfo?.token
          ? userInfo?.token
          : config.public_token,
      condition: {
        user: isLogin !== false && userInfo?.token ? userInfo?.email : null,
      },
    });
  }, [userInfo, setInfo, isLogin]);

  return useQuery(
    cartKeys.detail(info?.token),
    () => fetchCart({ userInfo: info }),
    {
      // staleTime: process.env.DEFAULT_STALE_TIME,
      enabled: !!info?.token,
    }
  );
};
