import {
  useFetchAppInfo,
  useFetchProductLine,
} from "@/apis/queryFunctions/appInfo";
import {
  useFetchEcompage,
  useFetchEcompages,
} from "@/apis/queryFunctions/ecompagesFunctions";
import useTrans from "@/hooks/useTrans";
import useWindowSize from "@/hooks/useWindowSize";
import {
  Accordion,
  Box,
  Flex,
  Grid,
  Group,
  Text,
} from "@mantine/core";
import { AiFillFacebook } from "@react-icons/all-files/ai/AiFillFacebook";
import { AiFillInstagram } from "@react-icons/all-files/ai/AiFillInstagram";
import { AiFillMail } from "@react-icons/all-files/ai/AiFillMail";
import { AiFillYoutube } from "@react-icons/all-files/ai/AiFillYoutube";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import theBoat2 from "public/Boat.svg";
import iconEmail from "public/icon_email.svg";
import iconHome from "public/icon_home.svg";
import iconPhone from "public/icon_phone.svg";
import logo from "public/logo2.png";
import wave from "public/wave.svg";
import React from "react";
import Container from "../Container/Container";
import Contact from "./contact";
import useStyles from "./styles";

export default function Footer() {
  const trans = useTrans();
  const { classes } = useStyles();
  const { locale } = useRouter();

  const { data: appInfo } = useFetchAppInfo({});

  const { data: productLines } = useFetchProductLine();

  const { data: huongDan } = useFetchEcompages({
    sort: { sst: 1 },
    limit: 5,
    condition: {
      ngon_ngu: locale,
      loai_tin_tuc: "Hướng dẫn",
      shared: true,
    },
  });
  const { data: cskh } = useFetchEcompages({
    sort: { sst: 1 },
    limit: 5,
    condition: {
      ngon_ngu: locale,
      loai_tin_tuc: "Chính sách khách hàng Việt Thiên",
      shared: true,
    },
  });


  const linkPttt = cskh?.find((item) => item?.slug === "chinh-sach-mua-hang", locale);
  const { data: lienHe } = useFetchEcompage("trang-lien-he", locale);




  const { isTablet, isDesktop } = useWindowSize();

  return (
    <div className={classes.container}>
      <div className={classes.subscribe}>
        <div className={classes.bgSubscribe}>
          <Image src={wave} alt="theWave" loading="lazy" layout="fill" objectFit={isDesktop ? "cover" : "contain"} />
        </div>
        <div className={classes.subscribeContent} style={{ right: isDesktop ? 100 : 10 }}>
          <Image src={theBoat2} alt="theBoat" loading="lazy" height={isDesktop ? 100 : 60} width={isDesktop ? 300 : 180} />
        </div>
      </div>

      <div className={classes.info}>
        <Container size="xl" sx={{ width: "100%" }}>
          <div className={classes.infoBody}>
            <Grid>
              <Grid.Col md={9}>

                <Grid >
                  <Grid.Col xs={12} sm={6} md={4} order={isTablet ? 1 : 1} >
                    <Flex grow direction={isTablet ? "row" : "column"} gap={isTablet ? 0 : "md"}>
                      <div className={classes.logoWrapper}>
                        <Link scroll={false} href="/">
                          <a>
                            <div className={classes.logo}>
                              <Image
                                src={logo}
                                alt="Việt Thiên"
                                title="Việt Thiên"
                                priority
                              />
                            </div>
                          </a>
                        </Link>
                      </div>
                      <div className={classes.nameCompany}>
                        <Text>
                          {locale === "vi" ? appInfo?.name : appInfo?.name_en}
                        </Text>
                      </div>
                    </Flex>
                  </Grid.Col>

                  <Grid.Col xs={12} sm={6} md={4} order={isTablet ? 3 : 2}>
                    {/* --------------------CHÍNH SÁCH KHÁCH HÀNG------------------------ */}
                    {isTablet ? (
                      <Accordion variant="separated" defaultValue="customization" sx={{ background: "#12854F" }}>
                        <Accordion.Item value="focus-ring">
                          <Accordion.Control sx={{ background: "#12854F", color: "#FFF" }}><InfoTitle>{trans?.footer.customerPolicy}</InfoTitle></Accordion.Control>
                          <Accordion.Panel sx={{ background: "#12854F" }}>
                            {cskh &&
                              cskh.map((item, k) => (
                                <Link
                                  scroll={false}
                                  href={`/chinh-sach/${item?.slug}`}
                                  key={k}
                                >
                                  <a>
                                    <InfoText className={classes.infoText}>
                                      {item?.title}
                                    </InfoText>
                                  </a>
                                </Link>
                              ))}
                            {/* <br /> */}
                            {Boolean(huongDan?.length) && <InfoTitle>Hướng dẫn</InfoTitle>}
                            {huongDan &&
                              Boolean(huongDan?.length) &&
                              huongDan?.map((item, k) => (
                                <Link
                                  scroll={false}
                                  href={`/huong-dan/${item?.slug}`}
                                  key={k}
                                >
                                  <a>
                                    <InfoText className={classes.infoText}>
                                      {item?.title}
                                    </InfoText>
                                  </a>
                                </Link>
                              ))}
                          </Accordion.Panel>
                        </Accordion.Item>
                      </Accordion>
                    ) : (
                      <>
                        <InfoTitle>{trans?.footer.customerPolicy}</InfoTitle>
                        {cskh &&
                          cskh.map((item, k) => (
                            <Link
                              scroll={false}
                              href={`/chinh-sach/${item?.slug}`}
                              key={k}
                            >
                              <a>
                                <InfoText className={classes.infoText}>
                                  {item?.title}
                                </InfoText>
                              </a>
                            </Link>
                          ))}
                        {/* <br /> */}
                        {Boolean(huongDan?.length) && <InfoTitle>Hướng dẫn</InfoTitle>}
                        {huongDan &&
                          Boolean(huongDan?.length) &&
                          huongDan?.map((item, k) => (
                            <Link
                              scroll={false}
                              href={`/huong-dan/${item?.slug}`}
                              key={k}
                            >
                              <a>
                                <InfoText className={classes.infoText}>
                                  {item?.title}
                                </InfoText>
                              </a>
                            </Link>
                          ))}
                      </>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6} md={4} order={isTablet ? 3 : 3}>
                    {/* ----------------------THÔNG TIN LIÊN HỆ--------------------------- */}

                    {isTablet ? (
                      <Accordion variant="separated" defaultValue="customization" sx={{ background: "#12854F" }}>
                        <Accordion.Item value="focus-ring">
                          <Accordion.Control sx={{ background: "#12854F", color: "#FFF" }}><InfoTitle>{trans?.footer.contactInfo}</InfoTitle></Accordion.Control>
                          <Accordion.Panel sx={{ background: "#12854F" }}>
                            {lienHe?.add_on_data?.map((item, index) => (
                              <InfoText className={classes.infoText2} key={index}>
                                {`${item?.title}: ${item?.summary}`}
                              </InfoText>
                            ))}
                          </Accordion.Panel>
                        </Accordion.Item>
                      </Accordion>
                    ) : (
                      <>
                        <InfoTitle>{trans?.footer.contactInfo}</InfoTitle>
                        {lienHe?.add_on_data?.map((item, index) => (
                          <InfoText className={classes.infoText2} key={index}>
                            {`${item?.title}: ${item?.summary}`}
                          </InfoText>
                        ))}
                      </>
                    )}
                  </Grid.Col>


                  <Grid.Col sx={12} sm={6} md={4} order={isTablet ? 2 : 6}>
                    <div className={classes.contactItem}>
                      <Image src={iconHome} alt="icon Home" title="Icon Home" width="30px" height="30px" />
                      <InfoText className={classes.infoText1}>
                        {locale === "vi" ? appInfo?.address : appInfo?.address_en}
                      </InfoText>
                    </div>

                    <div className={classes.contactItem}>
                      <Image src={iconEmail} alt="icon Email" title="Icon Email" width="20px" height="20px" />
                      <Link href={`mailto:${appInfo?.email}`} passHref>
                        <a target="_blank" className={classes.infoText1}>
                          {appInfo?.email ? `${appInfo?.email}` : ""}
                        </a>
                      </Link>
                    </div>

                    <div className={classes.contactItem}>
                      <Image src={iconPhone} alt="icon Phone" title="Icon Phone" width="20px" height="20px" />
                      <Text className={classes.infoText1}>
                        {productLines?.length > 0 ? productLines[0]?.phone : ""}
                      </Text>
                    </div>
                  </Grid.Col>
                  <Grid.Col sx={12} sm={6} md={5} order={isTablet ? 7 : 7}>
                    <Box mb="sm">
                      <Text size="sm" weight="normal">{trans?.footer.info1}</Text>
                      <Text size="sm" weight="normal">{trans?.footer.info2}</Text>
                    </Box>

                    <Box
                      style={{
                        position: "relative",
                        aspectRatio: "2.64",
                        width: isTablet ? "50%" : "30%",
                      }}
                    >
                      <Link
                        href="http://online.gov.vn/Home/WebDetails/104069"
                        passHref
                      >
                        <a target="_blank">
                          <Image
                            alt="bộ công thương"
                            title="Bộ Công Thương"
                            src="http://online.gov.vn/Content/EndUser/LogoCCDVSaleNoti/logoSaleNoti.png"
                            layout="fill"

                          />
                        </a>
                      </Link>
                    </Box>
                  </Grid.Col>
                </Grid>
              </Grid.Col>


              <Grid.Col md={3}>
                <Grid>
                  <Grid.Col xs={12} sm={6} md={12} order={isTablet ? 6 : 4} align="left">
                    <InfoTitle>{trans?.footer.followUs}</InfoTitle>
                    <Group style={{ fontSize: 24 }} mb={10}>
                      {productLines?.length > 0 && (
                        <Link scroll={false} href={productLines[0].facebook}>
                          <a target="_blank" aria-label="Facebook">
                            <AiFillFacebook />
                          </a>
                        </Link>
                      )}
                      {appInfo?.instagram && (
                        <Link scroll={false} href={appInfo?.instagram}>
                          <a target="_blank" aria-label="Instagram">
                            <AiFillInstagram />
                          </a>
                        </Link>
                      )}
                      {appInfo?.youtube && (
                        <Link scroll={false} href={appInfo?.youtube}>
                          <a target="_blank" aria-label="Youtube">
                            <AiFillYoutube />
                          </a>
                        </Link>
                      )}
                      {appInfo?.email && (
                        <Link scroll={false} href={`mailto:${appInfo?.email}`}>
                          <a target="_blank" aria-label="Email">
                            <AiFillMail />
                          </a>
                        </Link>
                      )}
                    </Group>
                    {/* <Link scroll={false} href="/tuyen-dung" aria-label="Career" id="main-menu-item" style={{ display: "flex", alignItems: "center" }}>
                  <a><div><Text fz='sm' transform="upperCase">{trans?.menu.recruitment.title}</Text></div></a>
                </Link> */}
                    <Contact />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </div>
        </Container>
      </div>
    </div>
  );
}

function InfoTitle({ children }) {
  return (
    <Text size="sm" transform="uppercase" weight="500" mb={12} color="#FFF">
      {children}
    </Text>
  );
}

function InfoText({ children, className }) {
  return (
    <Text size="sm" weight="normal" mb={4} className={className}>
      {children}
    </Text>
  );
}
