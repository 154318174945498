import { asyncGetItem, asyncGetList } from "@/apis/fetch";
import { useFetchCart } from "@/apis/queryFunctions/cartFunctions";
import { useFetchOrder } from "@/apis/queryFunctions/orderFuntions";
import { cartKeys } from "@/apis/queryKeys";
import DialogAction from "@/common/components/DialogAction/DialogAction";
import useUserInfo from "@/hooks/useUserInfo";
import { useApiContext } from "@/setup/flexbiz-api-provider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { appAlert } from "../mantine-provider/notifications";

// import CartContext from "../context/CartContext";

export const CartContext = React.createContext();

export const useCartContext = () => {
  const context = useContext(CartContext);
  return context;
};

export const config = {
  server_url_report: process.env.server_url_report,
  GOOGLE_RECAPTCHA_SITE_KEY: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
  id_app: process.env.id_app,
  group_id: process.env.group_id,
  trustkey: process.env.trustkey,
  system_code: process.env.system_code,
  again_link: "/checkout-alert",
  public_token: "flex.public.token",
};
const { id_app } = config;
const { public_token } = config;
const server_url = config.server_url_report;

function CartProvider(props) {
  const router = useRouter();
  const [listAddress, setListAddress] = useState([]);

  const [addressSelected, setAddressSelected] = useState({
    dia_chi: "",
    xa_phuong: "",
    quan_huyen: "",
    tinh_thanh: "",
  });

  const initalUserInfo = {
    ten_nguoi_nhan: "",
    sdt_nguoi_nhan: "",
    email: "",
    tinh_thanh: "",
    quan_huyen: "",
    xa_phuong: "",
  };

  const [userInfo, setUserInfo] = useState(initalUserInfo);

  const [infoOrder, setInfoOrder] = useState({
    tempPrice: 0,
    totalPrice: 0,
    feeShip: 0,
  });
  const [listVouchers, setListVouchers] = useState([]);
  const [pt_thanh_toan, setPt_thanh_toan] = useState("");
  const [loading, setLoading] = useState({});
  const [listPromotion, setListPromotion] = useState([]);
  const [voucherFreeShip, setVoucherFreeShip] = useState({});
  const [listOrderTemp, setListOrderTemp] = useState([]);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [isVisibleQuantityBtn, setIsVisibleQuantityBtn] = useState(false);
  const [listDVVC, setListDVVC] = useState([]); // Đơn vị vận chuyển
  const [donViVanChuyen, setDonViVanChuyen] = useState({});
  // const [wareHouse, setWareHouse] = useState({});
  const [paymentSelected, setPaymentSelected] = useState({});
  const [listPaymentMethod, setListPaymentMethod] = useState([]);
  const [onSubmitAddress, setOnSubmitAddress] = useState({});
  const [id_afflink, setId_afflink] = useState({});
  
  // console.log("Id Afflink", id_afflink);
  
  const dialogRef = React.useRef();

  const context = useApiContext();

  const { isLogin } = useUserInfo();

  const queryClient = useQueryClient();

  const { data: cart } = useFetchCart();


  const { data: info_order } = useFetchOrder();

  // console.log('infor Order',info_order.details)

  useEffect(() => {
    if (context.userInfo?.token) {
      queryClient.invalidateQueries(["so1"]);
    }
  }, [context.userInfo?.token]);

  // const fetchSeller = useCallback(async () => {
  //   const seller = await asyncGetList({
  //     collection_name: "ecomsellers",
  //   });

  //   return seller;
  // }, [context.userInfo?.token]);

  const fetchListDVVC = useCallback(async () => {
    const listDVVC = await context.apis.asyncGetList(
      context.userInfo?.token || public_token,
      "ecomcarrier_fees",
      {
        shared: true,
      }
    );

    return listDVVC;
  }, [context.userInfo?.token]);

  // const fetchWareHouse = useCallback(async () => {
  //   const listDVVC = await context.apis.asyncGetList(
  //     context.userInfo?.token || public_token,
  //     "ecomseller_warehousies",
  //     {
  //       online: true,
  //     }
  //   );
  //   if (listDVVC.length > 0) {
  //     return listDVVC[0];
  //   }
  // }, [context.userInfo?.token]);

  const fetchListPaymentMethod = useCallback(async () => {
    const listPaymentMethod = await context.apis.asyncGetList(
      isLogin === false ? public_token : context.userInfo?.token,
      "ptthanhtoan",
      {
        condition: {  
          online: true,
        },
      }
    );

    setListPaymentMethod(listPaymentMethod);
  }, [context.userInfo?.token, isLogin, public_token]);

  // const timeRef = useRef(0);

  useEffect(() => {
    Promise.all([fetchListDVVC()])
      .then(([listDVVC]) => {
        setListDVVC(listDVVC);
      })
      .catch((err) => {
        console.log("🚀 ~ file: index.js ~ line 86 ~ Promise.all ~ err", err);
      });
  }, [fetchListDVVC]);

  useEffect(() => {
    const fetchFeeShip = async () => {
      if (!cart || cart?.length <= 0) return;

      const amountCart = cart?.reduce((pre, cur) => pre + cur.tien_nt, 0);

      let freeShipValue = 0;
      let feeShip = 0;
      if (cart[0]?.freeship?.length > 0) {
        const conditionFreeShip = cart[0]?.freeship[0]?.gia_tri_dh;

        freeShipValue =
          amountCart >= conditionFreeShip
            ? cart[0]?.freeship[0]?.phi_van_chuyen || 0
            : 0;

        if (amountCart >= conditionFreeShip) {
          setVoucherFreeShip(cart[0]?.freeship[0]);
        } else {
          setVoucherFreeShip({});
        }
      }

      if (info_order?._id && router.pathname === "/checkout") {
        if (addressSelected) {
          const listFeeShip = await context.apis.asyncGetList(
            isLogin === false ? public_token : context.userInfo?.token,
            "ecomtransportfees",
            {
              condition: {
                ten_quan_huyen: addressSelected?.quan_huyen,
              },
              shared: true,
            }
          );

          if (listFeeShip.length > 0) {
            feeShip = listFeeShip[0]?.tien_phi;
          } else {
               feeShip = 35000;
          }
        }
        const totalFreeShip = Math.min(freeShipValue, feeShip);
        const totalAmount = info_order?.t_tt_nt + feeShip - totalFreeShip;

        setInfoOrder({
          totalPrice: totalAmount,
          tempPrice: info_order?.t_tien_nt,
          feeShip,
          voucherValue: info_order?.tien_evoucher,
          freeShipValue: totalFreeShip,
          tien_phi_vc: feeShip,
        });
        return;
      }

      setInfoOrder({
        totalPrice: amountCart,
        tempPrice: amountCart,
        feeShip,
      });
    };

    fetchFeeShip();
  }, [
    cart,
    donViVanChuyen,
    router.pathname,
    addressSelected?.quan_huyen,
    info_order,
  ]);

  const updateCartMutation = useMutation((data) => updateCart(data), {
    onSuccess: () => {
      // appAlert({ type: "success", message: "Cập nhật giỏ hàng thành công" });
    },
    onSettled: () => {
      queryClient.invalidateQueries(cartKeys.all);
    },
  });
  const addingCartMutation = useMutation(
    async ({ data, url }) =>
      context.apis.asyncPost(url, null, data, "POST", "addToCart"),
    {
      onSuccess: () => {
        // appAlert({ type: "success", message: "Thêm vào giỏ hàng thành công" });
      },
      onSettled: () => {
        queryClient.invalidateQueries(cartKeys.all);
      },
    }
  );
  const deleteCartMutation = useMutation(
    (url) => context.apis.asyncDelete(url, null),
    {
      onSuccess: () => {
        appAlert({ type: "success", message: "Cập nhật giỏ hàng thành công" });
      },
      onSettled: () => {
        queryClient.invalidateQueries(cartKeys.all);
      },
    }
  );

  const createOrderMutation = useMutation(
    async ({ order, url }) =>
      context.apis.asyncPost(url, null, order, "POST", "createOrder"),
    {
      onSuccess: () => {
        router.push("/checkout");
      },
      onSettled: async () => {
        queryClient.invalidateQueries("so1");
      },
    }
  );

  useEffect(() => {
    if (!addressSelected?._id) {
      updateAddressSelected();
    }
  }, [listAddress]);

  const addToCart = async (data) => {
    const updatingCartItem = cart?.find(
      (cartItem) =>
        cartItem.ma_vt === data.ma_vt &&
        cartItem.id_app_vt === data.id_app_vt &&
        cartItem.thuoc_tinh?._id === data.thuoc_tinh?._id
    );

    if (updatingCartItem) {
      updatingCartItem.sl_xuat += data.sl_xuat;
      updateCartMutation.mutate(updatingCartItem, {});
      return;
    }

    addNewItemToCart(data);
  };
  const addNewItemToCart = async (data) => {
    const id_app = data?.id_app;
    try {
      let token;
      if (context?.userInfo) {
        token = context.userInfo?.token;
        data.user = context.userInfo?.email;
        data.user_created = context.userInfo?.email;
      } else {
        token = public_token;
      }

      let thuoc_tinh = data?.thuoc_tinh;
      thuoc_tinh = thuoc_tinh && Object.assign(thuoc_tinh, { online: true });
      data = Object.assign(
        data,
        { thuoc_tinh },
        { online: true },
        { id_app_vt: id_app },
        { sl_xuat: data?.sl_xuat }
      );

      // context.toast(context.apis.getLabel("Đã thêm một sản phẩm vào giỏ"));

      // save to server

      const url = `${server_url}/api/${id_app}/cart?access_token=${token}`;

      addingCartMutation.mutate({ data, url });
    } catch (e) {
      console.error(e);
      context.alert(e.message || e.error || e);
    }
  };
  const updateCart = async (data) => {
    if (!data._id) return;

    let token;

    if (context.userInfo) {
      token = context.userInfo?.token;
    } else {
      token = public_token;
    }

    if (context.userInfo) {
      data.user = context.userInfo?.email;
    }

    try {
      const url = `${server_url}/api/${id_app}/cart/${data._id}?access_token=${token}`;
      let res;

      res = JSON.parse(
        await context.apis.asyncPost(url, null, data, "PUT", "updateCart")
      );
      if (res?.sl_xuat > res?.ton) {
        res = await context.apis.asyncPost(
          url,
          null,
          { ...data, ...{ sl_xuat: res.sl_xuat } },
          "PUT",
          "updateCart"
        );
      }
    } catch (e) {
      context.alert(e.message || e.error || e);
      console.error(e);
    }
  };

  const deleteCart = async (data) => {
    let token;
    if (context?.userInfo) {
      token = context.userInfo?.token;
      // data.user = context.userInfo?.email;
    } else {
      token = public_token;
    }
    try {
      if (data.length > 0) {
        Promise.all(
          data?.map(async (item) => {
            const url = `${server_url}/api/${id_app}/cart/${item._id}?access_token=${token}`;
            await context.apis.asyncDelete(url, null);
          })
        ).then(() => {
          queryClient.invalidateQueries("cart");
        });
        return;
      }

      if (!data._id) return;

      const url = `${server_url}/api/${id_app}/cart/${data._id}?access_token=${token}`;
      deleteCartMutation.mutate(url);
    } catch (e) {
      console.error(e);
    }
  };

  const checkRequired = () => {
    // console.log("submit valueaddressSelected", onSubmitAddress.values);
    // let addressSelected =
    //   isLogin !== false ? addressSelected : { ...onSubmitAddress.values };

    // const isVNPhoneMobile =
    //   /^(0|\+84)(\s|\.)?((3[1-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/;

    const isEmail =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

    if (addressSelected?.sdt_nguoi_nhan?.trim().length < 10) {
      context.alert("Số điện thoại không hợp lệ");
      return false;
    }
    if (
      addressSelected?.email_nguoi_nhan?.length &&
      !isEmail.test(addressSelected?.email_nguoi_nhan)
    ) {
      context.alert("Email không hợp lệ");
      return false;
    }
    if (
      // !addressSelected?.ten_nguoi_nhan ||
      !addressSelected?.dia_chi ||
      !addressSelected?.tinh_thanh ||
      !addressSelected?.quan_huyen ||
      !addressSelected?.xa_phuong
    ) {
      context.alert("Vui lòng nhập đầy đủ thông tin");
      return false;
    }
    return true;
    // if (!addressSelected?._id) {
    //   appAlert({
    //     type: "error",
    //     message: "Vui lòng chọn địa chỉ giao hàng",
    //   });
    //   return false;
    // }
    // return true;
  };

  const createOrder = async ({ trang_thai }) => {
    if (createOrderMutation.isLoading) {
      return;
    }
    if (trang_thai === "0") {
      if (!checkRequired()) return;
    }

    const ma_freeship = voucherFreeShip?.ma_chietkhau;

    let evouchers = voucherFreeShip;
    const { feeShip } = infoOrder;
    let leadTime;

    const freeshipValue = infoOrder.freeShipValue;

    const id_app = cart[0]?.id_app;

    const token = isLogin !== false ? context.userInfo?.token : public_token;

    const infoDVVC = {
      don_vi_vc: donViVanChuyen?.ma_dv_vc,
      goi_cuoc_vc: donViVanChuyen?.ten_goi_cuoc,
      thu_ho: paymentSelected?._id === "62f075b044ceb71181578d03",
    };

    const trang_thai_tt =
      paymentSelected?._id === "62f075b044ceb71181578d03" ? "1" : "0";

    let listOrder = cart;

    if (
      info_order?._id &&
      info_order.details?.length > 0 &&
      trang_thai !== "LCT"
    ) {
      listOrder = info_order.details;
      evouchers = info_order?.evouchers ?? [];
    }

    let order = {
      ma_ct: "SO1",
      id_afflink,
      ngay_ct: new Date(),
      ma_kho: process.env.ECOM_WARE_HOUSE_ID,
      ma_nt: "VND",
      t_tien_hang: listOrder
        ?.map((c) => c.tien_hang_nt)
        .reduce((a, b) => a + b, 0),
      t_tien_ck: listOrder?.map((c) => c.tien_ck).reduce((a, b) => a + b, 0),
      t_cp_cpb: 0,
      ty_le_ck_hd: 0,
      tien_ck_hd: 0,
      tien_freeship: freeshipValue,
      tien_phi_vc: feeShip,
      pt_thanh_toan: paymentSelected?._id,
      again_link: `https://${window.location.href.split("/")[2]}${
        config.again_link
      }`,
      trang_thai,
      trang_thai_tt,
      tien_evoucher: evouchers?.so_tien_giam,
      evouchers: [].concat(evouchers),
      ma_freeship,
      xa_phuong: addressSelected?.xa_phuong,
      dia_chi_nguoi_nhan: addressSelected?.dia_chi,
      ngay_du_kien_nhan: leadTime ? new Date(leadTime) : new Date(),
      online: true,
      ma_kenh: process.env?.SELLER_CHANNEL_CODE || "",
      ma_gian_hang: process.env?.SELLER_CODE_KH || "",
      id_app,
      id_afflink,
      shared: true,
      ...infoDVVC,
      ...addressSelected,
    };
    let orderPromotion = [];

    listPromotion?.forEach((item) => {
      let index;
      listOrder?.forEach((element) => {
        33;

        index = element?.promotion?.findIndex((pro) => pro._id === item._id);
        if (index >= 0) {
          orderPromotion = orderPromotion.concat({
            gia_ban: 0,
            sl_xuat: item.sl_km || 1,
            ...item?.details_km[0],
          });
        }
      });
    });

    order.details = listOrder?.concat(orderPromotion);

    order.details = order.details?.map((item) =>
      item?.ma_vt_tt
        ? {
            ...item,
            ma_vt: item.ma_vt_tt,
            ma_tt1: "",
            exfields_detail: {
              picture_tt: item.picture_tt || item.picture,
            },
          }
        : { ...item, ma_tt1: "" }
    );

    const orderSuccess = async () => {
      try {
        deleteCart(cart);
        setListVouchers([]);
        setVoucherFreeShip({});
      } catch (error) {
        console.log("error handle delete listOrder");
      }
    };

    const url = `${server_url}/api/${id_app}/so1?access_token=${token}`;

    try {
      if (trang_thai === "0") {
        const _id = info_order?._id;
        const so_ct = listOrder?.so_ct;
        order = Object.assign(order, { _id, so_ct, id_app });

        const master = await context.apis.asyncPostList(token, "so1", order, {
          id_app,
        });

        await orderSuccess(listOrder);
        if (
          master.virtual_payment_client_url &&
          (master.trang_thai === "0" || master.trang_thai === "1")
        ) {
          window.location.href = master.virtual_payment_client_url;
        } else if (trang_thai === "0") {
          router.push({
            pathname: "/checkout-alert",
            query: {
              isSuccess: "true",
              amount: master.t_tt_nt,
              isCOD: true,
              message: "Đặt hàng thành công",
              info_order: {},
              _id: master?._id,
              email: master?.email_nguoi_nhan,
            },
          });
        }
      } else {
        const listLCT = await context.apis.asyncGetList(token, "so1", {
          condition: {
            trang_thai: "LCT",
            user_created: context.userInfo?.email
              ? context.userInfo?.email
              : "public",
          },
        });

        Promise.all(
          listLCT?.map(
            async (item) =>
              await context.apis.asyncDeleteList(token, "so1", item?._id)
          )
        ).catch((error) => {
          console.log(
            "🚀 ~ file: index.js ~ line 487 ~ createOrder ~ error",
            error
          );
        });

        order = Object.assign(order, { tien_evoucher: 0, evouchers: [] });

        createOrderMutation.mutateAsync({ order, url });
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: index.js ~ line 532 ~ createOrder ~ error",
        error
      );
      // context.toast(
      //   error.message || error.error || error || "Có lỗi, vui lòng thử lại!"
      // );
    }
  };

  const updateAddressSelected = async (address) => {
    if (address) {
      if (!address._id) {
        setAddressSelected({});
      }
      setAddressSelected(address);
    } else if (listAddress?.length > 0) {
      const address = listAddress?.find((item) => item?.ngam_dinh === true);
      if (address?._id) {
        setAddressSelected(address);
        return;
      }
      setAddressSelected(listAddress[0]);
    }
  };

  const checkVoucher = async (vouchers) => {
    try {
      const index = listVouchers.findIndex((i) => i.ma === vouchers);

      if (index >= 0) {
        throw new Error("Bạn đã thêm mã giảm giá này");
      }

      const voucher = await context.apis.asyncGetList(
        context.userInfo?.token || public_token,
        "evoucher",
        {
          condition: {
            ma: vouchers,
            // hieu_luc_den: new Date(),
          },
        }
      );

      if (voucher.length < 1) {
        throw new Error("Mã giảm giá không tồn tại hoặc hết hạn sử dụng");
      }
      return voucher;
    } catch (error) {
      // context.setProgressStatus(false);
      context.toast(error.message || error.error || error);
    }
  };

  const addVoucher = async (voucher) => {
    try {
      // context.setProgressStatus(true);
      if (!voucher) {
        throw new Error("Voucher không khả dụng");
      }

      if (listVouchers > 0) {
        throw new Error("Bạn đã thêm mã giảm giá này");
      }

      const isVoucher = await checkVoucher(voucher);

      const listOrder = info_order?.details;

      const totalPrice = infoOrder?.totalPrice;
      if (isVoucher?.length > 0) {
        if (
          totalPrice >= isVoucher[0]?.gt_don_hang_toi_thieu ||
          isVoucher[0]?.gt_don_hang_toi_thieu === undefined
        ) {
          // const newListVouchers = listVouchers.concat(isVoucher);
          // setState({ listVouchers: /newListVouchers });
          const t_tien_hang = listOrder
            ?.map((c) => c.tien)
            .reduce((a, b) => a + b, 0);

          const tien_ck = listOrder
            ?.map((c) => c.tien_ck)
            .reduce((a, b) => a + b, 0);

          const tien_evoucher = isVoucher
            ?.map((e) => {
              let so_tien = e.ty_le
                ? Math.roundBy((t_tien_hang * e.ty_le) / 100, 0)
                : e.so_tien;
              if (e.so_tien_max && so_tien > e.so_tien_max) {
                so_tien = e.so_tien_max;
              }
              e.so_tien_giam = so_tien;
              return so_tien;
            })
            .reduce((a, b) => a + b, 0);

          let order = {
            evouchers: isVoucher,
            tien_evoucher:
              tien_evoucher > t_tien_hang - tien_ck
                ? t_tien_hang - tien_ck
                : tien_evoucher,
          };

          const _id = info_order?._id;

          const so_ct = info_order?.so_ct;

          const id_app = info_order?.id_app;

          order = Object.assign(order, { _id, so_ct });

          await context.apis.asyncPostList(
            isLogin === false ? public_token : context.userInfo?.token,
            "so1",
            order,
            {
              id_app,
            }
          );
          await queryClient.invalidateQueries("so1");

          // context.setProgressStatus(false);
        } else {
          throw new Error("Chưa đạt giá trị đơn hàng tối thiểu");
        }
      }
    } catch (error) {
      // context.setProgressStatus(false);
      context.alert(error.message || error.error || error?.data?.error);
    }
  };

  const fetchShipAddress = useCallback(async () => {
    const token = isLogin === false ? public_token : context.userInfo?.token;

    const condition =
      isLogin === false ? {} : { user_created: context.userInfo?.email };
    try {
      const listAddress = await context.apis.asyncGetList(
        token,
        "ecomuser_address",
        {
          limit: 10,
          page: 1,
          condition: {
            ...condition,
          },
        }
      );

      setListAddress(listAddress);
    } catch (error) {
      console.log("log errro fect address ---->", error);
    }
  }, [context?.userInfo?.token, isLogin]);

  const updatePaymentMethodSelected = (pt_thanh_toan) => {
    if (pt_thanh_toan) {
      setPt_thanh_toan(pt_thanh_toan);
    }
  };

  const deleteVoucher = async (orderItem) => {
    // context.setProgressStatus(true);
    let order = {
      evouchers: [],
      tien_evoucher: 0,
    };
    const _id = orderItem?._id;
    const so_ct = orderItem?.so_ct;
    order = Object.assign(order, { _id, so_ct });
    console.log(
      "🚀 ~ file: index.js ~ line 774 ~ deleteVoucher ~ order",
      order
    );
    try {
      const res = await context.apis.asyncPostList(
        isLogin === false ? public_token : context.userInfo?.token,
        "so1",
        order
      );
      await queryClient.invalidateQueries("so1");
      // context.setProgressStatus(false);
    } catch (error) {
      // context.setProgressStatus(false);
      context.alert(error?.message || error?.error || error?.data?.error);
    }
  };

  const addListPromotion = (product) => {
    let listPromotionClone = [...listPromotion];
    if (product.length > 0) {
      if (listPromotionClone?.length > 0) {
        product.forEach((e) => {
          e.promotion?.forEach((element) => {
            const details_km = {
              ...e?.promotion[0].details_km[0],
              id_app: e?.id_app,
            };
            element = { ...e?.promotion[0], details_km };

            if (element) {
              const index = listPromotionClone.findIndex(
                (promotion) => promotion?.ma_vt === element?.ma_vt
              );
              if (index >= 0) {
                return;
              }
              listPromotionClone = listPromotionClone.concat(element);

              setListPromotion(listPromotionClone);
            }
          });
        });
        return;
      }
      product.forEach((e) => {
        if (e.promotion?.length > 0) {
          // e = Object.assign(e, { ...product?.id_app });
          const details_km = [
            {
              ...e?.promotion[0].details_km[0],
              id_app: e?.id_app,
            },
          ];
          const promotion = { ...e?.promotion[0], details_km };

          listPromotionClone = listPromotionClone.concat(promotion);
        }
      });
      setListPromotion([...listPromotionClone]);
      return;
    }
    if (listPromotionClone.length > 0) {
      const index = listPromotionClone.findIndex(
        (i) => i.ma_vt === product.ma_vt
      );

      if (index >= 0) {
        listPromotionClone.fill(product, index, index + 1);
      } else {
        listPromotionClone = listPromotionClone.concat(product);
      }
      setListPromotion(listPromotionClone);
      return;
    }
    listPromotionClone = listPromotionClone.concat(product);
    setListPromotion(listPromotionClone);
  };

  const reOrder = async ({ _id, so_ct, paymentSelected }) => {
    const order = {
      _id,
      so_ct,
      pt_thanh_toan: paymentSelected,
      virtual_payment_client_url: "",
    };
    const master = await context.apis.asyncPostList(
      context.userInfo.token,
      "so1",
      order
    );

    if (
      master.virtual_payment_client_url &&
      (master.trang_thai === "0" || master.trang_thai === "1")
    ) {
      window.location.href = master.virtual_payment_client_url;
    } else {
      setOrderSuccess({
        isSuccess: "true",
        amount: order.t_tien_hang,
        isCOD: true,
        message: "Đặt hàng thành công",
      });
    }
  };

  const addVoucherFreeship = async (item, infoOrder) => {
    let order = {};
    const ma_freeship = item?.ma_chietkhau || "";
    const tien_freeship = item?.phi_van_chuyen
      ? feeShip?.tien_phi <= item?.phi_van_chuyen
        ? feeShip?.tien_phi
        : item?.phi_van_chuyen
      : 0;
    const _id = infoOrder?._id;
    const so_ct = infoOrder?.so_ct;

    order = Object.assign(order, {
      _id,
      so_ct,
      ma_freeship,
      tien_freeship,
      tien_phi_vc: feeShip?.tien_phi,
    });
    try {
      await context.apis.asyncPostList(context.userInfo.token, "so1", order, {
        id_app: infoOrder?.id_app,
      });
      // queryClient.invalidateQueries("so1");
    } catch (error) {
      context.alert(error.message || error.error || error);
    }
  };

  const updateOrderTemp = async (product) => {
    const orderTemp = listOrderTemp[0];

    product = {
      ...product,
      ...{ km_yn_for: "so1" },
    };
    const index = orderTemp?.details?.findIndex(
      (i) => i?._id === product?._id && product?.km_yn_for
    );
    if (product.sl_xuat <= 0 && index >= 0) {
      const listProduct = orderTemp?.details.filter(
        (i) => i._id !== product?._id
      );
      orderTemp.details = listProduct;
    } else if (index >= 0) {
      orderTemp.details[index] = product;
    } else {
      orderTemp.details = orderTemp?.details?.concat(product);
    }

    try {
      const master = await context.apis.asyncPostList(
        context.userInfo.token,
        "so1",
        orderTemp
      );
      const listOrder = [...listOrderTemp];
      listOrder.fill(master, 0, 1);
      setListOrderTemp(listOrder);
    } catch (error) {
      context.alert(error.message || error.error || error);
    }
  };

  const fetchVoucherFreeShip = async (item) => {
    const voucher = await context.apis.asyncGetListShared(
      context.userInfo.token,
      "ecompromotion_freeships",
      {
        condition: {
          ma_chietkhau: item,
        },
      }
    );
    return voucher;
  };

  const dialogAction = ({ title, message, onConfirm, onCancel }) => {
    dialogRef.current.handleClick(title, message, onConfirm, onCancel);
  };

  const submitAddress = async (infoUser) => {
    try {
      const id = addressSelected._id === infoUser._id ? infoUser._id : "";
      const data = {
        ...infoUser,
        ...{ _id: id },
      };

      const submit = async (token) => {
        const address = await context.apis.asyncPostList(
          token,
          "ecomuser_address",
          isLogin === false
            ? {
                ...data,
                ngam_dinh: true,
              }
            : data
        );

        queryClient.invalidateQueries("ecomuser_address");
        if (address?._id) {
          await fetchShipAddress();

          await updateAddressSelected(address);
        }
      };

      const clearDefault = async () => {
        listAddress &&
          listAddress?.map(async (list2) => {
            list2.ngam_dinh = false;
            try {
              await context.apis.asyncPostList(
                isLogin === false ? public_token : context.userInfo.token,
                "ecomuser_address",
                { id_app, ...list2 }
              );
            } catch (e) {
              console.log(e);
            }
          });
      };
      // const deleteAddress = async () => {
      //   listAddress?.length > 1 &&
      //     Promise.all(
      //       listAddress.map(async (address) => {
      //         if (
      //           address.ngam_dinh === true &&
      //           address._id === addressSelected._id
      //         ) {
      //           return;
      //         }
      //         await context.apis.asyncDeleteList(
      //           public_token,
      //           "ecomuser_address",
      //           address._id
      //         );
      //       })
      //     );
      //   queryClient.invalidateQueries("ecomuser_address");
      // };

      if (data?.ngam_dinh) {
        clearDefault();
      }

      if (isLogin === false) {
        // deleteAddress();
        submit(public_token);
      } else {
        const index = listAddress?.findIndex((i) => i.ngam_dinh === true); // check address default
        if (index === -1) {
          data.ngam_dinh = true;
        }
        submit(context.userInfo?.token);
      }
    } catch (e) {
      console.log("🚀 ~ file: index.js ~ line 1031 ~ submitAddress ~ e", e);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        listAddress,
        addressSelected,
        listVouchers,
        pt_thanh_toan,
        loading,
        listPromotion,
        voucherFreeShip,
        listOrderTemp,
        orderSuccess,
        isVisibleQuantityBtn,
        fetchListDVVC,
        addVoucherFreeship,
        addToCart,
        updateCart,
        deleteCart,
        createOrder,
        updateAddressSelected,
        checkVoucher,
        addVoucher,
        fetchShipAddress,
        updatePaymentMethodSelected,
        setIsVisibleQuantityBtn,
        deleteVoucher,
        addListPromotion,
        reOrder,
        updateOrderTemp,
        listDVVC,
        fetchVoucherFreeShip,
        dialogAction,
        setDonViVanChuyen,
        donViVanChuyen,
        infoOrder,
        updateCartMutation,
        setUserInfo,
        fetchListPaymentMethod,
        listPaymentMethod,
        setPaymentSelected,
        userInfo,
        info_order,
        setInfoOrder,
        submitAddress,
        isProcessingCart:
          addingCartMutation?.isLoading ||
          updateCartMutation?.isLoading ||
          deleteCartMutation?.isLoading,
        isProcessingOrder: createOrderMutation?.isLoading,
        onSubmitAddress,
        setOnSubmitAddress,
        setAddressSelected,
        id_afflink,
        setId_afflink,
      }}
    >
      {props.children}
      <DialogAction ref={dialogRef} />
    </CartContext.Provider>
  );
}

export default CartProvider;
