import { createStyles } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";

import flagChina from "public/icon_ChiFlag.svg";
import flagEn from "public/icon_EnFlag.svg";
import flagInd from "public/icon_IndFlag.svg";
import flagVn from "public/icon_VnFlag.svg";

const useStyles = createStyles(() => ({
  language: {
    position: "relative",
    // paddingLeft: "8px",
  },
  languageItem: {
    marginRight: "5px",
    cursor: "pointer",
    width: "100%",
    height: "100%",
/*     borderRadius: "50%", */
    overflow: "hidden",
  },
  languageImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  languageMenu: {
    position: "absolute",
    top: "100%",
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    padding: "5px",
    zIndex: 1,
  },
  languageMenuItem: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "5px",
  },
  languageMenuItemImage: {
    width: "25px",
    height: "25px",
    marginRight: "8px",
    /* borderRadius: "50%", */
    overflow: "hidden",
  },
  languageMenuItemText: {
    fontSize: "18px",
    fontWeight: 450,
  },
}));

export default function Language() {
  const { classes } = useStyles();
  const { locale, asPath, push } = useRouter();
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const languageRef = useRef(null);
  const [selectedLanguage, setSelectedLanguage] = useState("vi");

  const handleLanguageItemClick = (selectedLocale) => {
    setIsLanguageMenuOpen(false);
    if (selectedLocale !== locale) {
      setSelectedLanguage(selectedLocale);
      push(asPath, asPath, { locale: selectedLocale });
    }
  };

  const handleLanguageMenuToggle = () => {
    setIsLanguageMenuOpen(!isLanguageMenuOpen);
  };

  useEffect(() => {
    setSelectedLanguage(locale); 
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        isLanguageMenuOpen &&
        languageRef.current &&
        !languageRef.current.contains(e.target)
      ) {
        setIsLanguageMenuOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isLanguageMenuOpen]);

  return (
    <div className={classes.language} ref={languageRef}>
      <div className={classes.languageItem} onClick={handleLanguageMenuToggle}>
        <Image src={selectedLanguage === "vi" ? flagVn : flagEn} alt={selectedLanguage === "vi" ? "Ngôn ngữ Việt" : "English"} className={classes.languageImage} width={27} height={27} objectFit="cover"/>
      </div>

      {isLanguageMenuOpen && (
        <div className={classes.languageMenu}>
          <div
            className={classes.languageMenuItem}
            onClick={() => handleLanguageItemClick("vi")}
          >
            <div className={classes.languageMenuItemImage}>
              <Image src={flagVn} alt="Ngôn ngữ Việt" width={25} height={25} objectFit="cover"/>
            </div>
            <div className={classes.languageMenuItemText}>Vietnamese</div>
          </div>

          <div className={classes.languageMenuItem}
               onClick={() => handleLanguageItemClick("en")}>
            <div className={classes.languageMenuItemImage}>
              <Image src={flagEn} alt="English" width={25} height={25} objectFit="cover"/>
            </div>
            <div className={classes.languageMenuItemText}>English</div>
          </div>
        </div>
      )}
    </div>
  );
}
