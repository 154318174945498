/* eslint-disable react/react-in-jsx-scope */
import { postContact } from "@/apis/queryFunctions/contact";
import useTrans from "@/hooks/useTrans";
import { appAlert } from "@/setup/mantine-provider/notifications";
import { Box, Button, Grid, Group, Text, Textarea, TextInput, } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMutation } from "@tanstack/react-query";


export default function Contact() {

    const trans = useTrans();


    const form = useForm({
        initialValues: {
            ho_ten: "",
            dien_thoai: "",
            email: "",
            dia_chi: "",
            noi_dung: "",
        },
        validate: {
            ho_ten: (value) =>
                value.length > 0 ? null : "Vui lòng nhập họ tên của quý khách",
            email: (value) =>
                value.length > 0
                    ? /^\S+@\S+$/.test(value)
                        ? null
                        : "Email không hợp lệ"
                    : "Vui lòng nhập email để cửa hàng liên hệ với quý khách",

            noi_dung: (value) => (value.length > 0 ? null : "Vui lòng nhập nội dung"),
        },
    });


    const contactMutation = useMutation(postContact, {
        onSuccess: (rs) => {
            if (rs._id) {
                appAlert({ type: "success", message: "Gửi thông tin thành công" });
            } else {
                appAlert({ message: "Gửi thông tin không thành công" });
            }
            form.reset();
        },
    });

    const handleSubmit = () => {
        contactMutation.mutate({ ...form.values });
    };

    return (
        <Box sx={{ align: "center", position: "relative", }} >
            <Grid>
                <Grid.Col xs={12} sm={12} >

                    <form onSubmit={form.onSubmit(handleSubmit)}>
                        <TextInput

                            style={{ margin: 2, border: '1.2px solid black', borderRadius: 7 }}
                            withAsterisk

                            size="xs"
                            placeholder={`${trans?.contact.placeholder.fullName} *`}
                            {...form.getInputProps("ho_ten")}
                            mb={12}
                        />
                        <TextInput
                            style={{ margin: 2, border: '1.2px solid black', borderRadius: 7 }} size="xs"
                            placeholder={`${trans?.contact.placeholder.phone} *`}
                            {...form.getInputProps("dien_thoai")}
                            mb={12}
                        />
                        <TextInput
                            style={{ margin: 2, border: '1.2px solid black', borderRadius: 7 }}                            // bg={}
                            withAsterisk
                            size="xs"
                            placeholder={trans?.contact.placeholder.email}
                            {...form.getInputProps("email")}
                            mb={12}
                        />
                        <TextInput
                            style={{ margin: 2, border: '1.2px solid black', borderRadius: 7 }} size="xs"
                            placeholder={trans?.contact.placeholder.address}
                            {...form.getInputProps("dia_chi")}
                            mb={12}
                        />
                        <Textarea
                            style={{ margin: 2, border: '1.2px solid black', borderRadius: 7 }} placeholder={trans?.contact.placeholder.content}
                            withAsterisk
                            size="xs"
                            autosize
                            minRows={2}
                            maxRows={2}
                            {...form.getInputProps("noi_dung")}
                        />
                        <Group position="center">
                            <Button
                                type="submit"
                                h={30}
                                fullWidth
                                variant="filled"
                                // bg="#12854F"
                                radius="xl"
                                mt={5}
                                style={{ boxShadow: '10px 10px 8px rgba(0, 0, 0, 0.15)' }}

                            >
                                <Text fw="bold" transform="uppercase">
                                    {trans?.contact.form.button}
                                </Text>
                            </Button>
                        </Group>
                    </form>
                </Grid.Col>
            </Grid>
        </Box>
    )
}