/* eslint-disable jsx-a11y/anchor-is-valid */
import useAuth from "@/hooks/useAuth";
import useTrans from "@/hooks/useTrans";
import useUserInfo from "@/hooks/useUserInfo";
import { useLocalContext } from "@/setup/locale-provider";
import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import { Avatar, Box, Divider, Drawer, NavLink, Text, TextInput, useMantineTheme } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import logo from "public/logo.png";
import React from "react";
import LanguageMobile from "./LanguageMobile";
// import Language from "./Language";

export default function DrawerMenu({ opened, setOpened }) {
  const { getLabel } = useLocalContext();
  const trans = useTrans();
  const { userInfo, isLogin } = useUserInfo();
  const { logout } = useAuth();
  const theme = useMantineTheme();
  const {asPath} = useRouter();

  return (
    <Drawer
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <Link scroll={false} href="/" passHref>
          <a>
            <Box onClick={() => setOpened(false)}>
            <Image src={logo} alt="logo" width={64} height={40} />
            </Box>
          </a>
        </Link>
      }
      padding="sm" size="xs"
    >
      <NavLink
        label={<TextInput size="sm" placeholder={getLabel("Tìm kiếm...")} />}
      />
      {isLogin && (
        <>
          <NavLink
            label={<Text size="md">{userInfo?.name}</Text>}
            icon={
              <Avatar
                src={appendImageUrlFromAPI({ src: userInfo?.picture,})} alt={userInfo?.name} radius="xl" size="sm"/>
          }>
            <NavLink label={<Link scroll={false} href="/user-profile/my-profile">
                  <a><Text size="md" transform="uppercase"  color={asPath==="/user-profile/my-profile"?theme.colors.second[3]:theme.colors.second[0]} onClick={() => setOpened(false)}>{getLabel("Hồ sơ")}</Text></a>
                </Link>
            }/>
            <NavLink label={<Link scroll={false} href="/user-profile/address-book">
                  <a><Text size="md" transform="uppercase" color={asPath==="/user-profile/address-book"?theme.colors.second[3]:theme.colors.second[0]} onClick={() => setOpened(false)}>{getLabel("Sổ địa chỉ")}</Text></a>
                </Link>
            }/>
            <NavLink label={<Link scroll={false} href="/user-profile/purchased-return">
                  <a><Text size="md" transform="uppercase" color={asPath==="/user-profile/purchased-return"?theme.colors.second[3]:theme.colors.second[0]} onClick={() => setOpened(false)} >{getLabel("Đơn hàng")}</Text></a>
                </Link>
            }/>
            <NavLink label={<Link scroll={false} href="/user-profile/liked-product">
                  <a><Text size="md"  transform="uppercase" color={asPath==="/user-profile/liked-product"?theme.colors.second[3]:theme.colors.second[0]} onClick={() => setOpened(false)}>{getLabel("Sản phẩm yêu thích")}</Text></a>
                </Link>
            }/>
          </NavLink>
          <Divider />
        </>
      )}
      <NavLink label={<Link scroll={false} href="/gioi-thieu" passHref>
            <a><Text size="lg" weight="bold" onClick={() => setOpened(false)} color="#2F302F">{trans?.menu.about.title}</Text></a>
          </Link>
      }/>
                <NavLink label={ <Text size="lg" weight="bold" color="#2F302F">{trans?.menu.products.title}</Text>}
                  childrenOffset={28}
                >
                  <NavLink label={<Link scroll={false} href="/san-pham/tra">
                        <a><Text size="lg" weight="bold" color="#2F302F"> {trans?.menu.products.tea}</Text></a>
                      </Link>
                  }/>
                  <NavLink label={<Link scroll={false} href="/san-pham/ca-phe">
                        <a><Text size="lg" weight="bold" color="#2F302F">{trans?.menu.products.coffee}</Text></a>
                      </Link>} 
                  />
                  <NavLink label={<Link scroll={false} href="/san-pham/nong-san">
                        <a><Text size="lg" weight="bold" color="#2F302F">{trans?.menu.products.agricultural} </Text></a>
                      </Link>
                  }/>
                  <NavLink label={<Link scroll={false} href="/san-pham/nuoc-tang-luc">
                        <a><Text size="lg" weight="bold" color="#2F302F">{trans?.menu.products.energyDrink} </Text></a>
                      </Link>
                  }/>
                </NavLink>
      {/* -------------------------------Nội Địa---------------------------- */}
      <NavLink label={<Link scroll={false} href="/noi-dia" passHref>
                <a><Text size="lg" weight="bold" color="#2F302F" onClick={() => setOpened(false)}>{trans?.menu.noidia.title}</Text></a>
              </Link>
      }/>
      {/* ----------------------------------Xuất Khẩu------------------------------ */}
      <NavLink label={<Link scroll={false} href="/xuat-khau" passHref>
            <a><Text size="lg" weight="bold" color="#2F302F" onClick={() => setOpened(false)}>{trans?.menu.xuatkhau.title}</Text></a>
          </Link>
      }/>
      {/* -----------------------------------Liên hệ------------------------------- */}
      <NavLink label={<Link scroll={false} href="/lien-he" passHref>
            <a><Text size="lg" weight="bold" color="#2F302F" onClick={() => setOpened(false)}>{trans?.menu.contact.title}</Text></a>
          </Link>
      }/>
      {/* ------------------------------Tin Tức----------------------------- */}
      <NavLink label={<Link scroll={false} href="/tin-tuc" passHref>
                <a><Text size="lg" weight="bold" color="#2F302F" onClick={() => setOpened(false)}>{trans?.menu.news.title}</Text></a>
          </Link>
      }/>
      <NavLink label={<Link scroll={false} href="/tuyen-dung" passHref>
                <a><Text size="lg" weight="bold" color="#2F302F" onClick={() => setOpened(false)}>{trans?.menu.recruitment.title}</Text></a>
          </Link>
      }/>
      <Divider />
      <Text ml={12} mt={10} size="lg" weight="bold" color="#2F302F">{trans?.menu.language.title}</Text>
      <LanguageMobile/>
    </Drawer>
  );
}
